import { Configuration, LogLevel } from "@azure/msal-browser";

const config: Configuration = {
  auth: {
    clientId: "7473965d-8e19-4b85-9d1f-7bf42c32d3c8",
    authority: "https://login.microsoftonline.com/59a8d640-a5ec-45dc-a6e7-5f5241e445d6/",
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: true,
  },
  cache: { cacheLocation: "localStorage", storeAuthStateInCookie: true },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export default config;
