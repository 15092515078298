import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import authProvider from "./AuthConfig";

import { LoadingScreen } from "./components/LoadingScreen";
import { ViewLoader } from "./views/viewLoader/viewLoader";

import "./output.css";
import { useToggle } from "./hooks/useToggle";
import { useEventListener } from "./hooks/useEventlistener";
import { useAccount, useIsAuthenticated, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { useSavedAccount } from "./hooks/useSavedAccount";
import { InteractionRequiredAuthError, InteractionType, SilentRequest } from "@azure/msal-browser";
import { BrowserRouter } from "react-router-dom";
import Calendar from "./components/Calendar/Calendar";

const LoadingEvents = {
  HIDE_LOAD: "HIDE_LOAD",
  SHOW_LOAD: "SHOW_LOAD",
};

export const AppLoader = () => {
  const [isShowingLoadingScreen, , showLoading, hideLoading] = useToggle(true);
  const isAuthenticated = useIsAuthenticated();

  const { instance } = useMsal();
  const [account, setAccount] = useSavedAccount();
  const acc = useAccount(instance.getActiveAccount() || account || instance.getAllAccounts()[0] || {});

  const config: SilentRequest = useMemo(
    () => ({
      scopes: [
        "https://metaobjects1.sharepoint.com/AllSites.Write",
        "https://metaobjects1.sharepoint.com/MyFiles.Write",
        "https://metaobjects1.sharepoint.com/Sites.Search.All",
      ],
      account: acc ?? undefined,
      loginHint: acc?.username ?? ((acc?.idTokenClaims as any)?.emails ?? [undefined])[0] ?? undefined,
      forceRefresh: false,
    }),
    [acc]
  );
  const { result, error, login } = useMsalAuthentication(InteractionType.Silent, config);

  useEffect(() => {
    if (result) {
      setAccount(result.account);
    } else if (error) {
      console.error("silent login failed");
      if (error instanceof InteractionRequiredAuthError) {
        login(InteractionType.Redirect, config);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, error, setAccount]);

  const handleRedirect = useCallback(async () => {
    try {
      const resp = await instance.handleRedirectPromise();
      if (resp !== null) {
        const { account } = resp;
        setAccount(account);
      }
      return null;
    } catch (error) {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance.handleRedirectPromise, setAccount]);

  useEffect(() => {
    handleRedirect();
  }, [handleRedirect]);

  useEventListener(LoadingEvents.HIDE_LOAD, hideLoading);
  useEventListener(LoadingEvents.SHOW_LOAD, showLoading);

  return (
    <>
      <LoadingScreen isVisible={isShowingLoadingScreen} />
      {isAuthenticated ? (
        <BrowserRouter>
          <ViewLoader path="app" componentName="App"></ViewLoader>
        </BrowserRouter>
      ) : null}
    </>
  );
};

export default AppLoader;
