import * as React from "react";
import { TailwindBackgroundColors } from "../models/General";

const height = {
  height: "2px",
};

export const LoadingBar = (props: {
  color?: TailwindBackgroundColors;
  onColorChange?: (color: TailwindBackgroundColors) => any;
  initialColor?: TailwindBackgroundColors;
}) => {
  const [currentIntensities, setCurrentIntensities] = React.useState<
    (100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900)[]
  >([100, 500, 200, 300, 900, 800]);

  const color = React.useMemo(
    () =>
      ({
        100: "bg-primary-100",
        200: "bg-primary-200",
        300: "bg-primary-300",
        400: "bg-primary-400",
        500: "bg-primary-500",
        600: "bg-primary-600",
        700: "bg-primary-700",
        800: "bg-primary-800",
        900: "bg-primary-900",
      } as { [key in 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900]: string }),
    []
  );

  const interval = React.useRef<ReturnType<typeof setInterval> | null>(null);

  React.useEffect(() => {
    interval.current = setInterval(() => {
      setCurrentIntensities((intensity) =>
        intensity.map((inte) => {
          if (inte !== 900) {
            return (inte + 100) as any;
          } else {
            return 100 as any;
          }
        })
      );
    }, 120);
    return () => {
      if (interval.current) clearInterval(interval.current);
    };
  }, []);

  const lines = React.useMemo(
    () =>
      currentIntensities.map((intensity, index) => {
        return (
          <div
            key={index}
            className={`flex-1 h-full transition-all duration-200 ${color[intensity] ?? "bg-primary-500"}`}
          ></div>
        );
      }),
    [currentIntensities, color]
  );

  return (
    <>
      <div className="flex flex-grow-0 w-full rounded-full opacity-80 z-20" style={height}>
        {lines}
      </div>
    </>
  );
};
