import { useState, useCallback, useMemo, useEffect, useRef } from "react";
import { useEventListener } from "../hooks/useEventlistener";
import packageJson from "../../package.json";
import { TailwindBackgroundColors } from "../models/General";
import Tools from "../utility/Tools";

export const POSSIBLE_MANDANTEN: ("UnitTesting" | "Test" | "Prod")[] = ["UnitTesting", "Test", "Prod"];
const LAST_MANDANT_SAVELOCATION = "LAST_MANDANT_SAVELOCATION";

const isComparable = (value: any) => {
  if (
    typeof value === "undefined" ||
    typeof value === "bigint" ||
    typeof value === "boolean" ||
    typeof value === "number" ||
    typeof value === "string" ||
    typeof value === "symbol" ||
    (value && value.toISOString) ||
    value === null ||
    value === undefined
  ) {
    return true;
  } else return false;
};

const isObjectOfSameKind = (value: object, compare: object) =>
  Object.keys(value).every((k) => Object.keys(compare).some((sk) => k === sk));

const compareObject = (first: object, second: object) => {
  if (isObjectOfSameKind(first, second)) {
    const firstKeys: (keyof typeof first)[] = Object.keys(first) as (keyof typeof first)[];
    const isSame = firstKeys.reduce((is, b) => {
      if (is === true) {
        if (isComparable(first[b]) && isComparable(second[b])) {
          return first[b] === second[b];
        } else {
          return false;
        }
      }
      return is;
    }, true);
    return isSame;
  } else {
    return false;
  }
};

export type AppSettings = {
  serverVersion: number;
  clientVersion: number;
  serviceWorkerActive: boolean;
  serviceWorkerCachedPage: boolean;
  serviceWorkerUpdate: boolean;
  version: string;
  mandant: typeof POSSIBLE_MANDANTEN[0];
  syncMandant: typeof POSSIBLE_MANDANTEN[0];
  title: string;
  date: Date;
  displayMode: "Web" | "Mobile" | "Tablet";
  isStandalone: boolean;
  isAppCached: boolean;
  shouldShowInstallPrompt: boolean;
  loadedDefaultSettings: boolean;
  dataColor: { [key: string]: TailwindBackgroundColors };
  showServerApiError: boolean;
  deviceID?: string;
  isSyncing: boolean | null;
};
const lastMandant = localStorage.getItem(LAST_MANDANT_SAVELOCATION);

const searchParams = new URLSearchParams(window.location.search);
const dateString = searchParams.get("dateString");
const searchDate = dateString ? new Date(dateString) : null;

let defaultSettings: AppSettings = {
  serverVersion: 0,
  clientVersion: 0,
  serviceWorkerActive: false,
  serviceWorkerCachedPage: false,
  serviceWorkerUpdate: false,
  version: packageJson.version || "??",
  title: `Tagesabrechnung\n${window.location.hostname} - v${packageJson.version}`,
  mandant:
    window.location.hostname.includes("dev") || window.location.hostname === "localhost"
      ? (lastMandant as typeof POSSIBLE_MANDANTEN[0]) || POSSIBLE_MANDANTEN[0]
      : POSSIBLE_MANDANTEN[2],
  syncMandant:
    window.location.hostname.includes("dev") || window.location.hostname === "localhost"
      ? (lastMandant as typeof POSSIBLE_MANDANTEN[0]) || POSSIBLE_MANDANTEN[0]
      : POSSIBLE_MANDANTEN[2],
  date: searchDate ?? new Date(),
  displayMode: "Web",
  isStandalone:
    window.matchMedia("(display-mode: standalone)").matches ||
    (window.navigator && (window.navigator as any).standalone) ||
    document.referrer.includes("android-app://"),
  isAppCached: false,
  shouldShowInstallPrompt: false,
  loadedDefaultSettings: false,
  dataColor: {
    umsaetze: "blue",
    abrechnungen: "red",
  },
  showServerApiError: true,
  deviceID: undefined,
  isSyncing: null,
};

let Settings: AppSettings = {
  ...defaultSettings,
};

const _getSettings = () => ({ ...Settings });

const get = <T extends keyof AppSettings>(key: T) => {
  return { ...Settings }[key];
};

const set = <T extends keyof AppSettings>(key: T, value: typeof Settings[T]): typeof Settings[T] => {
  Reflect.set(Settings, key, value);
  localStorage.setItem(`Settings_${key === "mandant" ? value : Settings.mandant}`, JSON.stringify(Settings));
  const event = new CustomEvent<string>(Events.SETTINGS_UPDATED, {
    detail: key.toUpperCase(),
  });
  window.dispatchEvent(event);
  return Settings[key];
};

const Events = {
  SETTINGS_ALIVE: "SETTINGS_ALIVE",
  SETTINGS_UPDATED: "SETTINGS_UPDATED",
};

export const useSettings = () => {
  const [settings, setSettings] = useState<AppSettings>(Settings);

  const settingsKeys: (keyof typeof settings)[] = useMemo(
    () => Object.keys(settings).filter((k) => k !== "user") as (keyof typeof settings)[],
    [settings]
  );
  const handleSettingsChange = useCallback(() => {
    const change = !settingsKeys.every((v) => {
      if (isComparable(settings[v])) {
        return settings[v] === get(v);
      } else {
        return true;
      }
    });
    if (change) {
      setSettings(_getSettings());
    }
  }, [settings, settingsKeys]);

  useEffect(() => {
    handleSettingsChange();
  });

  const isoDate = useMemo(() => Tools.dateToIsoLike(settings.date), [settings.date]);

  useEventListener([Events.SETTINGS_UPDATED, Events.SETTINGS_ALIVE], handleSettingsChange);

  const _handleMandantChange = useCallback((ev: CustomEvent<keyof AppSettings>) => {
    if (ev.detail.toUpperCase() === "MANDANT") {
      localStorage.setItem(LAST_MANDANT_SAVELOCATION, get("mandant"));
      const defSettings = localStorage.getItem(`Settings_${get("mandant")}`);
      if (defSettings) {
        const parsedDef: AppSettings = JSON.parse(defSettings);
        const {
          date,
          version,
          serviceWorkerActive,
          serviceWorkerCachedPage,
          serviceWorkerUpdate,
          showServerApiError,
          isSyncing,
          ...strippedParsedDef
        } = parsedDef;
        defaultSettings = { ...defaultSettings, ...strippedParsedDef };
        Settings = { ...Settings, ...defaultSettings };
        set("loadedDefaultSettings", true);
      }
    }
  }, []);

  useEventListener(Events.SETTINGS_UPDATED, _handleMandantChange);

  return { ...settings, isoDate, set, Events, changeMobileBarColor };
};

const changeMobileBarColor = () => {
  const body = document.body;
  const transition = "200ms background-color ease-in-out";
  if (body && body.style && body.style.transition && body.style.transition !== transition) {
    body.style.transition = transition;
  }
  const metaThemeColor = document.querySelector("meta[name=theme-color]");
  const metaBackgroundColor = document.querySelector("meta[name=background-color]");
  const colorGrabber = document.getElementById("colorGrabber");
  if (colorGrabber) {
    const color = window.getComputedStyle(colorGrabber).backgroundColor;
    if (body.style.backgroundColor !== color) {
      body.style.backgroundColor = color;
    }
    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", color);
    }
    if (metaBackgroundColor) {
      metaBackgroundColor.setAttribute("content", color);
    }
  }
};

export default { set, get, Events, POSSIBLE_MANDANTEN };

// Extrahieren?
