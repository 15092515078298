import { useRef, useEffect } from "react";

export const useEventListener = (
  eventName: string | string[] | undefined,
  handler: (...props: any) => any,
  element: HTMLElement | null | typeof window = window,
  passive: boolean = true
) => {
  const eventRef = useRef(eventName);
  const savedHandler = useRef(handler);
  const elementRef = useRef(element);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    eventRef.current = eventName;
  }, [eventName]);

  useEffect(() => {
    elementRef.current = element;
  }, [element]);

  useEffect(() => {
    const el = elementRef.current;
    const isSupported = el && el.addEventListener;
    if (!isSupported) return;

    const eventListener = (event: Event) => savedHandler.current(event);
    if (el) {
      const name = eventRef.current;
      if (Array.isArray(name)) {
        name.forEach((entry) => {
          el.addEventListener(entry, eventListener, { passive });
        });
        return () => {
          name.forEach((entry) => {
            el.removeEventListener(entry, eventListener);
          });
        };
      } else {
        if (name) {
          el.addEventListener(name, eventListener, { passive });
          return () => {
            el.removeEventListener(name, eventListener);
          };
        } else return;
      }
    }
  }, [passive]);
};
