import React, { useRef } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTheme } from "../../hooks/useTheme";

const views = {
  app: () => import("../../App"),
  foto: () => import("../fotos/fotoView"),
  newFotoModal: () => import("../fotos/modals/NewFotoModal"),
  kalender: () => import("../kalender/kalenderView"),
  personal: () => import("../personal/personalView"),
  tagesabrechnung: () => import("../tagesabrechnung/tagesabrechnungView"),
  umsatz: () => import("../umsatz/umsatzView"),
  events: () => import("../events/eventView"),
  zeit: () => import("../zeit/zeitView"),
  upload: () => import("../upload/uploadView"),
  fetchToy: () => import("../fetchToy/FetchToyView"),
  migration: () => import("../migration/migrationView"),
  aufgaben: () => import("../aufgabe/AufgabeMainView"),
  cleanup: () => import("../cleanup/cleanupView"),
};

export const ViewLoader = (props: {
  props?: any;
  path: keyof typeof views;
  componentName?: string;
  loadingComponent?: (...p: any) => JSX.Element;
  onLoadFinished?: () => void;
}) => {
  const [isLoadingView, setIsLoadingView] = useState(false);

  const view = useRef<((p: any) => JSX.Element) | null>(null);

  const loadView = useCallback(async () => {
    try {
      setIsLoadingView(true);
      const importedComponent = await views[props.path]();
      if (props.componentName) {
        if (props.componentName in importedComponent) {
          view.current = (importedComponent as any)[props.componentName];
        } else if ("default" in importedComponent) {
          if (props.componentName in importedComponent["default"]) {
            view.current = (importedComponent as any).default[props.componentName];
          } else {
            view.current = (importedComponent as any).default;
          }
        } else {
          throw new Error(`Component ${props.componentName} seems to be missing in ${props.path}`);
        }
      } else {
        view.current = importedComponent as any;
      }
    } catch (error) {
      throw error;
    }
  }, [props.path, props.componentName]);

  useEffect(() => {
    loadView().then(() => {
      setIsLoadingView(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadView, props.onLoadFinished]);

  useEffect(() => {
    if (isLoadingView) {
      return () => {
        if (props.onLoadFinished && !isLoadingView) props.onLoadFinished();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadView, props.onLoadFinished]);

  const currentView = useMemo(
    () =>
      isLoadingView ? (
        props.loadingComponent ? (
          <props.loadingComponent></props.loadingComponent>
        ) : (
          <Loading></Loading>
        )
      ) : view.current ? (
        <view.current {...props.props}></view.current>
      ) : null,
    [isLoadingView, props]
  );

  return currentView;
};

const Loading = () => {
  const { theme } = useTheme();

  return (
    <div className="flex w-full h-full min-h-0 flex-col justify-center items-center">
      <div className="relative flex w-24 h-24 rounded-full bg-gray-300 shadow-inner p-2 overflow-hidden">
        <div className="absolute flex top-0 left-0 w-full h-full justify-center items-start animate-spin rounded-full shadow-inner overflow-hidden">
          <div className={`h-12 w-12 bg-primary-500 transform rounded-lg shadow-md`}></div>
        </div>
        <div className="relative flex justify-center items-center text-center leading-none w-full h-full bg-gray-100 rounded-full shadow-inner">
          <span className="text-gray-500 text-xxs uppercase font-semibold tracking-wide">Loading...</span>
        </div>
      </div>
    </div>
  );
};
