import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import * as React from "react";
import ReactDOM from "react-dom";
import AuthConfig from "./AuthConfig";
import { AppLoader } from "./indexApp";
import "./output.css";

export const PublicClientApp = new PublicClientApplication(AuthConfig);

ReactDOM.render(
  <MsalProvider instance={PublicClientApp}>
    <AppLoader />
  </MsalProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
