type GetLength<original extends any[]> = original extends { length: infer L } ? L : never;

function formatBytes(bytes: number, decimals: number = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

function compareISOStrings(a: string, b: string, inverted?: boolean) {
  const options = ["de-de", { numeric: true }] as [string | string[], Intl.CollatorOptions | undefined];
  if (inverted) {
    return a.localeCompare(b, ...options);
  } else {
    return b.localeCompare(a, ...options);
  }
}

function sliceArrayIntoParts<T>(array: T[], chunkSize: number) {
  if (array.length <= chunkSize) return [1, [array]] as [number, T[][]];
  const chunkAmount = Math.ceil(array.length / chunkSize);
  const chunks: T[][] = Array(chunkAmount)
    .fill(null)
    .map((_, idx) => {
      const offset = idx * chunkSize;
      return array.slice(offset, offset + chunkSize);
    });
  return [chunkAmount, chunks] as [number, T[][]];
}

const iOSFocus = (el: HTMLInputElement) => {
  const fakeInput = document.createElement("input");
  fakeInput.setAttribute("readonly", "true");
  fakeInput.style.position = "absolute";
  fakeInput.style.opacity = "0";
  fakeInput.style.height = "0";
  fakeInput.style.fontSize = "16px";
  document.body.prepend(fakeInput);
  fakeInput.focus();

  return setTimeout(() => {
    el.focus();
    fakeInput.remove();
  }, 300);
};

function isoLikeToDate(isoLike: string, hours?: number, minutes?: number) {
  try {
    const temp = new Date(isoLike);
    if (hours || minutes) {
      if (hours) temp.setHours(hours);
      if (minutes) temp.setMinutes(minutes);
    }
    return temp;
  } catch (error) {
    throw error;
  }
}

function dateToIsoLike(date: Date) {
  const doublenought = "00";
  const tempDate = new Date(date);
  const [day, month] = [tempDate.getDate(), tempDate.getMonth() + 1].map((c) =>
    `${doublenought}${c.toString()}`.slice(-2)
  );

  const isoShort = [tempDate.getFullYear(), month, day].join("-");
  return isoShort;
}

function dateToIsoLikeAlternative(date: Date) {
  const year = date.getFullYear().toLocaleString("de-DE", { minimumIntegerDigits: 4, useGrouping: false });
  const month = (date.getMonth() + 1).toLocaleString("de-DE", { minimumIntegerDigits: 2, useGrouping: false });
  const day = date.getDate().toLocaleString("de-DE", { minimumIntegerDigits: 2, useGrouping: false });

  return [year, month, day].join("-");
}

function getCalendarWeek(d: Date) {
  const TAG_IN_MS = 86400000;
  const puresDatum = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate())); // Datum ohne Zeitzone offset;
  const wochenTagIndex = d.getUTCDay() || 7; // 0 (falsy) ist Sonntag, austauschen mit 7
  const naechsterDonnerstag = d.getUTCDate() + 4 - wochenTagIndex; // Position von Donnerstag ermitteln als "Mitte" der Woche

  puresDatum.setUTCDate(naechsterDonnerstag);
  const ersterTagImJahr = new Date(Date.UTC(d.getFullYear(), 0, 1));

  const differenz = +puresDatum - +ersterTagImJahr;
  const differenzInTagen = differenz / TAG_IN_MS + 1; // +1 da differenz 0 sein könnte
  const differenzInWochen = differenzInTagen / 7;
  const kalenderWoche = Math.ceil(differenzInWochen);

  return kalenderWoche;
}

const compose = <T extends ((...p: any) => any)[], L extends T[GetLength<T>]>(funcArr: T, last: L) =>
  funcArr.reduce((lastValue, currentFunc) => currentFunc(lastValue), undefined as any) as ReturnType<L>;

const numberTo2FractionDigits: (n?: number) => string = (numb) =>
  numb?.toLocaleString("de-de", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) ?? "--";

const Tools = {
  formatBytes,
  compareISOStrings,
  sliceArrayIntoParts,
  iOSFocus,
  dateToIsoLike,
  isoLikeToDate,
  compose,
  numberTo2FractionDigits,
  getCalendarWeek,
  dateToIsoLikeAlternative,
};

export default Tools;
