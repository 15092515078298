import * as React from "react";
import { useSettings } from "../clientApi/SettingsProvider";
import { useTheme } from "../hooks/useTheme";
import { AppColors, TailwindBackgroundColors } from "../models/General";
import { Heroicon, Heroicons } from "../utility/Heroicon";
import { LoadingBar } from "./LoadingBar";

const containerStyle = {
  top: "-50vh",
  left: "-40vw",
  width: "180vw",
  height: "200vh",
  transition: "2000ms all",
  backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='rgba(0,0,0,0.5)' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`,
};

const rotation = {
  transform: "rotate(30deg)",
};

const zindex = {
  zIndex: 200,
};

export const LoadingScreen = (props: { isVisible?: boolean; initialColor?: AppColors["backgroundcolor"] }) => {
  const { theme } = useTheme();
  const { changeMobileBarColor } = useSettings();
  const [currentColor, setCurrentColor] = React.useState(theme.backgroundcolor);

  const [isHiding, setIsHiding] = React.useState(!props.isVisible);

  const _handleStatusColor = React.useCallback(() => {
    changeMobileBarColor();
  }, [changeMobileBarColor]);

  React.useEffect(() => {
    _handleStatusColor();
  });

  const _handleColorChange = React.useCallback((color: TailwindBackgroundColors) => {
    setCurrentColor(color);
  }, []);

  const columns = React.useMemo(
    () => (
      <div
        className={`w-full h-full inline-flex justify-center items-stretch container mr-auto ml-auto transform gap-3`}
        style={rotation}
      >
        <div className={`h-full inline-grid grid-cols-1 slideIntroReverse gap-3 flex-1`}>
          <IconElement icon="ChatIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="UsersIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="BadgeCheckIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="MailIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="CheckIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="ChatAltIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="ClockIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="PhoneIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="ChatAlt2Icon" currentColor={currentColor}></IconElement>
        </div>
        <div className="h-full inline-grid grid-cols-1 slideIntro gap-3 flex-1">
          <IconElement icon="BadgeCheckIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="MailIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="PhoneIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="ChatAlt2Icon" currentColor={currentColor}></IconElement>
          <IconElement icon="ClockIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="ChatIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="UsersIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="ChatAltIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="ClockIcon" currentColor={currentColor}></IconElement>
        </div>
        <div className="h-full inline-grid grid-cols-1 slideIntroReverse gap-3 flex-1">
          <IconElement icon="PhoneIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="MailIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="BookmarkIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="HomeIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="CalendarIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="UsersIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="BookmarkIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="ChatAltIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="SearchIcon" currentColor={currentColor}></IconElement>
        </div>
        <div className={`h-full inline-grid grid-cols-1 slideIntro gap-3 flex-1`}>
          <IconElement icon="UsersIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="BookmarkIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="PhoneIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="MailIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="CheckIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="ChatAltIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="SearchIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="BookmarkIcon" currentColor={currentColor}></IconElement>
          <IconElement icon="HomeIcon" currentColor={currentColor}></IconElement>
        </div>
      </div>
    ),
    [currentColor]
  );

  const timeout = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  React.useEffect(() => {
    if (props.isVisible) {
      setIsHiding(false);
    } else {
      timeout.current = setTimeout(() => {
        setIsHiding(true);
      }, 4750);
      return () => {
        if (timeout.current) clearTimeout(timeout.current);
      };
    }
  }, [props.isVisible]);

  return (
    <div
      className={`absolute w-full h-full top-0 transition-opacity duration-700 ease-in-out ${
        props.isVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      } delay-300 z-50 ${isHiding ? "hidden" : ""}`}
    >
      <div
        className="absolute w-full h-full flex flex-col justify-center items-center z-50 top-0 shadow-inner bg-white bg-opacity-10"
        style={zindex}
      >
        <div className="flex justify-center items-center flex-col h-40 w-full ml-auto mr-auto bg-gray-900 bg-opacity-30">
          {props.isVisible ? (
            <>
              <h2 className="flex h-20 w-full justify-center items-center text-5xl leading-none text-white font-light">
                Willkommen
              </h2>
              <div className="h-4 w-full flex justify-center items-center px-6">
                <LoadingBar initialColor={props.initialColor} onColorChange={_handleColorChange}></LoadingBar>
              </div>

              <h3 className="flex h-16 w-full justify-center items-center text-2xl leading-none text-white font-light">
                Einen Moment noch...
              </h3>
            </>
          ) : (
            <h2 className="flex h-20 w-full justify-center items-center text-5xl leading-none text-white font-light">
              Los geht's!
            </h2>
          )}
        </div>
      </div>{" "}
      <div
        className={`absolute w-full h-full bg-primary-800 text-primary-500 z-50 overflow-hidden flex shadow-inner z-10 fill-current`}
        style={containerStyle}
      >
        {columns}
      </div>
    </div>
  );
};

const IconElement: React.FC<{
  currentColor: TailwindBackgroundColors;
  icon: Heroicon;
}> = (props) => {
  const IconEl = React.useMemo(() => Heroicons.Solid[props.icon], [props.icon]);

  return (
    <div
      className={`flex flex-1 h-full w-full bg-primary-700 rounded-xl justify-center items-center p-3 text-primary-500 transition-all duration-1000`}
    >
      <IconEl className="w-full h-full" />
    </div>
  );
};
