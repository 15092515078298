import * as AllIcons from "@heroicons/react/solid";
import * as Solid from "@heroicons/react/solid";
import * as Outline from "@heroicons/react/outline";

export type Heroicon = keyof typeof AllIcons;

export const Heroicons: {
  Solid: { [key in Heroicon]: typeof Solid[key] };
  Outline: { [key in Heroicon]: typeof Outline[key] };
} = {
  Solid,
  Outline,
};
