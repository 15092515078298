import { AccountInfo } from "@azure/msal-common";
import { useMsal } from "@azure/msal-react";
import { useCallback, useEffect, useState } from "react";

const accountLocation = "ACCOUNT";

export const getSavedAccount = () => {
  const resp = localStorage.getItem(accountLocation);
  if (resp) {
    try {
      const parsed = JSON.parse(resp);
      return parsed;
    } catch (error) {
      console.error(error);
      return null;
    }
  } else return null;
};

export const useSavedAccount = (acc?: AccountInfo) => {
  const [savedAccount, setSavedAccount] = useState<AccountInfo | null>(
    localStorage[accountLocation] ? JSON.parse(localStorage[accountLocation]) || null : null
  );

  const { instance, accounts } = useMsal();

  const clearAccount = useCallback(() => {
    instance.setActiveAccount(null);
    localStorage.removeItem(accountLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance.setActiveAccount]);

  useEffect(() => {
    if (acc) setSavedAccount(acc);
  }, [acc]);

  useEffect(() => {
    if (accounts && accounts[0]) {
      const temp = accounts[0];
      setSavedAccount(temp as AccountInfo);
    }
  }, [accounts]);

  useEffect(() => {
    if (savedAccount) {
      instance.setActiveAccount(savedAccount);
      localStorage.setItem(accountLocation, JSON.stringify(savedAccount));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance.setActiveAccount, savedAccount]);

  return [savedAccount, setSavedAccount, clearAccount] as [
    AccountInfo | null,
    (acc: AccountInfo | null) => void,
    () => void
  ];
};
